import React from 'react'
import "../Css/Pricing.css"

const Pricing = () => {
    return (
        <div>
            <div className='pricing'>
                <div className='price1'>
                    /
                </div>
                <div className='contact-header'>
                    <h1>
                        PLANS & PRICING
                    </h1>
                </div>
                <div className='price2'>
                    /
                </div>
            </div>

            <div className='pricee'>

            </div>
        </div>
    )
}

export default Pricing