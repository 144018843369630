import React from 'react'
import "../Css/Classes.css"
import BootCamp from '../Pages/BootCamp'


const Classes = () => {

  return (
    <div>
     <BootCamp/>
    </div>
  )
}

export default Classes