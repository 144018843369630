import React from 'react'
import "../Css/Wake.css"

const Wake = () => {
  return (
    <div className='wake'>
        <div >
            <div>
                <div>
                    <h2>
                        WAKE UP AND WORKOUT.
                    </h2>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Wake