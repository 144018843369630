import React from 'react'
import "../Css/Classes.css"

const Class = () => {
  return (
    <div>
          <div className='class'>
          <div className='class1'>
                    /
                </div>
                <div className='contact-header'>
                    <h1>
                        OUR <br/>
                        CLASSES
                    </h1>
                </div>
                <div className='class2'>
                    /
                </div>
        </div>

        </div>
    // </div>
  )
}

export default Class